import React from "react";
import "./DexoNft.js";
import GIF from "../assets/images/Dexo NFT Landing.gif";
import { DexoNftContainer } from "./DexoNft.js";
const DexoNftLanding = () => {
  return (
    <DexoNftContainer>
      <div className="center">
        <img className="image-full" src={GIF} alt="" />
      </div>
    </DexoNftContainer>
  );
};

export default DexoNftLanding;
