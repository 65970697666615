import styled, { css } from "styled-components";

export const DexoNftContainer = styled.div`
  max-width: 1920px;
  padding: 50px 32px;
  box-sizing: border-box;
  margin: auto;

  @media (max-width: 1025px) {
    padding: 8px 32px;
  }
  .image-full {
    width: 70%;
    margin: auto;
  }
  @media (max-width: 1025px) {
    .image-full {
      width: 100%;
      margin: auto;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mx-auto {
    margin: 0 auto !important;
  }
`;
