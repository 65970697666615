import styled from 'styled-components'

export const StatsTabItemContainer = styled.div`
    padding: 26px 58px;
`;

export const StatsHeader = styled.div`
    display: flex;
    align-items: center;

    svg {
      fill: #AAFF26;
    }

    .header-text {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      text-align: right;
      color: #FFFFFF;
      margin-left: 12px;
    }
`;

export const StatsFilter = styled.div`
    width: 130px;
    margin-top: 20px;
`;

export const StatsBody = styled.div`
    
`;